.icon {
  display: inline-block;
  vertical-align: top;
}
.am-search-wrap {
  min-height: 900px;
  padding-top: 55px;
}
.panel-search-top {
  display: table;
  width: 100%;
  height: 82px;
}
.panel-search-name,
.panel-search-toggler {
  display: table-cell;
  vertical-align: middle;
}
.panel-search-toggler {
  width: 170px;
  padding-left: 30px;
}
.panel-search-toggler-btn {
  float: right;
  display: block;
  text-decoration: none;
  width: 140px;
  height: 23px;
  line-height: 21px;
  color: #999;
  border: 1px dotted currentColor;
  border-radius: 3px;
  text-align: center;
  font-size: 0;
}
.panel-search-toggler-btn:hover {
  background-color: #999;
  color: #fff;
}
.panel-search-toggler-btn__txt {
  color: currentColor;
  font-size: 0.75rem;
  display: inline;
  vertical-align: middle;
}
.panel-search-toggler-btn__icn {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  width: 10px;
  height: 10px;
  fill: currentColor;
}
.panel-search-name__title {
  font-size: 1.875rem;
  font-weight: 300;
  color: #4b4b4b;
}
.panel-search-filter {
  padding-bottom: 35px;
}
.am-search-list {
  list-style: none;
  padding-bottom: 20px;
}
.am-search-hint {
  background-color: #f9fcfc;
  padding: 15px;
  position: relative;
  border: 1px dashed #419e98;
  margin-bottom: 30px;
}
.am-search-hint-query {
  position: absolute;
  width: 155px;
  height: 75px;
  top: -53px;
  right: 0;
  background: url("../img/main/search-query.png") no-repeat center transparent;
}
.am-search-hint-query__goto {
  color: #4b4b4b;
  font-size: 0.75rem;
  position: absolute;
  left: 25px;
  bottom: 13px;
  line-height: 1;
  text-decoration: none;
  display: block;
}
.am-search-hint-query__goto:hover {
  text-decoration: underline;
}
.am-search-hint__title {
  text-transform: uppercase;
  color: #4594a2;
  font-weight: bold;
  line-height: 1;
  font-size: 0.875rem;
  border-radius: 5px;
  margin-bottom: 10px;
}
.am-search-hint__txt {
  font-weight: 300;
  color: #5f5f5f;
  line-height: 1.2;
  padding-left: 25px;
  font-style: italic;
}
.am-search-accords {
  font-size: 0;
  border: 1px solid #66d4d1;
  padding-left: 20px;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 30px;
}
.am-search-accords_80 {
  background-color: #68bab4;
  background: linear-gradient(to bottom, #5dc2bb 0%, #44a69f 49%, #3da29b 50%, #4ba9a2 100%);
}
.am-search-accords_70 {
  background-color: #5dc2bb;
  background: linear-gradient(to bottom, #5dc2bb 0%, #4fbdb5 49%, #44b8b0 50%, #51bdb6 100%);
}
.am-search-accords_30 {
  border-color: #e8e8e8;
  background-color: #d0d0d0;
  background: linear-gradient(to bottom, #d0d0d0 0%, #cdcdcd 49%, #c8c8c8 50%, #ccc 100%);
}
.am-search-accords_30 .am-search-accords-match {
  border-right-color: #c0c0c0;
}
.am-search-accords_30 .am-search-accords-menu {
  border-left-color: #dadada;
}
.am-search-accords-match {
  display: inline-block;
  vertical-align: middle;
  padding-right: 20px;
  border-right: 1px solid #31938b;
  text-align: center;
  font-weight: 300;
}
.am-search-accords-match__txt {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.75rem;
  text-transform: uppercase;
  text-align: right;
  line-height: 1;
  width: 100px;
}
.am-search-accords-match__cnt {
  font-size: 1.875rem;
  display: inline-block;
  vertical-align: middle;
  margin-left: 13px;
}
.am-search-accords-menu {
  display: inline-block;
  vertical-align: middle;
  border-left: 1px solid #63c6c1;
  padding: 0 10px;
}
.am-search-accords-yes,
.am-search-accords-no,
.am-search-accords-unknown {
  display: inline-block;
  vertical-align: middle;
}
.am-search-accords-yes-label,
.am-search-accords-no-label,
.am-search-accords-unknown-label {
  display: block;
  height: 42px;
  line-height: 42px;
  text-align: center;
  padding: 0 20px;
  text-align: center;
}
.am-search-accords-yes-label__txt,
.am-search-accords-no-label__txt,
.am-search-accords-unknown-label__txt {
  font-size: 1.125rem;
  vertical-align: middle;
}
.am-search-accords-yes-label__icn,
.am-search-accords-no-label__icn,
.am-search-accords-unknown-label__icn {
  font-size: 0.625rem;
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}
.am-search-quests {
  list-style: none;
  margin: 0 -14px;
  font-size: 0;
}
.am-search-quests-cell {
  padding: 0 14px;
  display: inline-block;
  vertical-align: top;
  width: 25%;
  margin-bottom: 28px;
}
.am-search-more {
  padding-bottom: 30px;
}
.am-search-more__btn {
  border-radius: 3px;
  width: 120px;
  display: block;
  height: 32px;
  line-height: 30px;
  margin: 0 auto 20px;
}
.am-search-more__loader {
  display: block;
  height: 30px;
  width: 30px;
  margin: 0 auto;
}
.bc-search-cand {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #b4b4b4;
  background-color: #fafafa;
  width: 100%;
  font-size: 1rem;
  position: relative;
}
.bc-search-cand-acts {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}
.bc-search-cand-head {
  height: 270px;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.bc-search-cand-over {
  display: block;
  background: url("../img/bg/profile-bg-locked.png") repeat center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.bc-search-cand-lock {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 45px;
  height: 63px;
  margin-top: -31.5px;
  margin-left: -22.5px;
}
.bc-search-cand-lock__icn {
  width: 100%;
  height: 100%;
  fill: rgba(255,255,255,0.7);
  stroke: rgba(0,0,0,0.1);
  stroke-width: 3;
}
.bc-search-cand-lock-hint {
  width: 15px;
  height: 15px;
  padding: 3px;
  position: absolute;
  bottom: -5px;
  right: -5px;
}
.bc-search-cand-lock-hint .icn-help-cont {
  bottom: 12px;
  right: 12px;
}
.bc-search-cand-status {
  position: absolute;
  color: #fff;
  font-size: 0.625rem;
  width: 60px;
  height: 14px;
  font-weight: 300;
  line-height: 14px;
  bottom: 10px;
  left: 10px;
  text-align: center;
  border-radius: 3px;
  z-index: 1;
}
.bc-search-cand-status_on {
  background-color: #95cd7d;
}
.bc-search-cand-status_off {
  background-color: #bababa;
}
.bc-search-cand-ava {
  width: 100%;
  height: 100%;
}
.bc-search-cand-ava__img {
  display: block;
  width: 100%;
  height: 100%;
}
.bc-search-cand_locked .bc-search-cand-ava__img {
  filter: blur(10px);
}
.bc-search-cand-body {
  padding: 10px;
  position: relative;
}
.bc-search-cand-bday {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  right: 40%;
  margin-top: -20px;
}
.bc-search-cand-top {
  display: table;
  width: 100%;
  height: 44px;
}
.bc-search-cand-name,
.bc-search-cand-like {
  display: table-cell;
  vertical-align: middle;
}
.bc-search-cand-name {
  padding-right: 10px;
}
.bc-search-cand-name__txt {
  font-weight: bold;
  color: #343434;
  font-size: 1rem;
  overflow: hidden;
  text-decoration: none;
}
.bc-search-cand-name__txt:hover {
  text-decoration: underline;
}
.bc-search-cand-like {
  width: 90px;
}
.bc-search-cand-like__btn {
  display: block;
  width: 100px;
  height: 30px;
  line-height: 28px;
  border-radius: 3px;
  font-size: 0.875rem;
  font-weight: normal;
}
.bc-search-cand-match {
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 1;
}
.bc-search-cand-match__cnt {
  font-size: 2.25rem;
  line-height: 1;
  color: #fff;
  font-weight: 300;
}
.mm-search {
  background: #308079;
  background: linear-gradient(to bottom, #308079 0%, #53a7a0 100%);
  box-shadow: inset 0px 0px 3px 0px rgba(0,0,0,0.49);
  border-radius: 3px;
  padding: 13px 9px 15px;
}
.mm-search-head {
  padding: 0 2px 0 5px;
  margin-bottom: 10px;
}
.mm-search-head:after {
  display: block;
  clear: both;
  content: '';
}
.mm-search-head-lft {
  padding-top: 6px;
  float: left;
}
.mm-search-head-rgt {
  float: right;
}
.mm-search-head__title {
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 300;
  color: #fff;
}
.mm-search-head-type {
  display: block;
  list-style: none;
  font-size: 0;
}
.mm-search-head-type-itm {
  display: inline-block;
  vertical-align: middle;
  color: #9bbfbc;
  margin-left: 5px;
  border-radius: 4px;
}
.mm-search-head-type-itm_active,
.mm-search-head-type-itm:hover {
  color: #fff;
}
.mm-search-head-type-itm_active {
  background-color: #2d756f;
}
.mm-search-head-type-itm__lnk {
  display: block;
  padding: 12px;
  line-height: 1;
  text-transform: uppercase;
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  font-size: 0.875rem;
}
.mm-search-filter:after {
  display: block;
  clear: both;
  content: '';
}
.mm-search-filter-lft {
  float: left;
  width: calc(100% - 140px);
}
.mm-search-filter-rgt {
  float: right;
  width: 120px;
}
.mm-search-filter-btn {
  width: 100%;
  height: 44px;
  display: block;
  border-radius: 3px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  line-height: 42px;
}
.mm-search-filter-opts {
  width: 100%;
  display: table;
  position: relative;
}
.mm-search-filter-m-sex,
.mm-search-filter-m-place,
.mm-search-filter-m-city,
.mm-search-filter-m-date,
.mm-search-filter-m-more,
.mm-search-filter-pl-sex,
.mm-search-filter-pl-age,
.mm-search-filter-pl-city,
.mm-search-filter-pl-goal,
.mm-search-filter-pl-more,
.mm-search-filter-vc-sex,
.mm-search-filter-vc-age,
.mm-search-filter-vc-city,
.mm-search-filter-vc-goal,
.mm-search-filter-vc-more,
.mm-search-filter-p-type,
.mm-search-filter-p-city,
.mm-search-filter-p-place,
.mm-search-filter-p-price-from,
.mm-search-filter-p-price-to,
.mm-search-filter-p-more,
.mm-search-filter-pub-type,
.mm-search-filter-pub-city,
.mm-search-filter-pub-place,
.mm-search-filter-pub-price-from,
.mm-search-filter-pub-price-to,
.mm-search-filter-pub-more {
  display: table-cell;
  vertical-align: top;
}
.mm-search-filter-m-sex__select,
.mm-search-filter-m-place__select,
.mm-search-filter-m-city__select,
.mm-search-filter-m-date__select,
.mm-search-filter-m-more__select,
.mm-search-filter-pl-sex__select,
.mm-search-filter-pl-age__select,
.mm-search-filter-pl-city__select,
.mm-search-filter-pl-goal__select,
.mm-search-filter-pl-more__select,
.mm-search-filter-vc-sex__select,
.mm-search-filter-vc-age__select,
.mm-search-filter-vc-city__select,
.mm-search-filter-vc-goal__select,
.mm-search-filter-vc-more__select,
.mm-search-filter-p-type__select,
.mm-search-filter-p-city__select,
.mm-search-filter-p-place__select,
.mm-search-filter-p-price-from__select,
.mm-search-filter-p-price-to__select,
.mm-search-filter-p-more__select,
.mm-search-filter-pub-type__select,
.mm-search-filter-pub-city__select,
.mm-search-filter-pub-place__select,
.mm-search-filter-pub-price-from__select,
.mm-search-filter-pub-price-to__select,
.mm-search-filter-pub-more__select {
  width: 100%;
  height: 44px;
  appearance: none;
  border: 1px solid #959595;
  border-right: none;
  font-size: 0.75rem;
  color: #666;
  padding: 0 20px;
  background: url("/arrow-down-gray.svg#base64") no-repeat 95% 50% #fff;
}
.mm-search-filter-m-sex__select:hover,
.mm-search-filter-m-place__select:hover,
.mm-search-filter-m-city__select:hover,
.mm-search-filter-m-date__select:hover,
.mm-search-filter-m-more__select:hover,
.mm-search-filter-pl-sex__select:hover,
.mm-search-filter-pl-age__select:hover,
.mm-search-filter-pl-city__select:hover,
.mm-search-filter-pl-goal__select:hover,
.mm-search-filter-pl-more__select:hover,
.mm-search-filter-vc-sex__select:hover,
.mm-search-filter-vc-age__select:hover,
.mm-search-filter-vc-city__select:hover,
.mm-search-filter-vc-goal__select:hover,
.mm-search-filter-vc-more__select:hover,
.mm-search-filter-p-type__select:hover,
.mm-search-filter-p-city__select:hover,
.mm-search-filter-p-place__select:hover,
.mm-search-filter-p-price-from__select:hover,
.mm-search-filter-p-price-to__select:hover,
.mm-search-filter-p-more__select:hover,
.mm-search-filter-pub-type__select:hover,
.mm-search-filter-pub-city__select:hover,
.mm-search-filter-pub-place__select:hover,
.mm-search-filter-pub-price-from__select:hover,
.mm-search-filter-pub-price-to__select:hover,
.mm-search-filter-pub-more__select:hover {
  background-color: #f6f6f6;
}
.mm-search-filter-m-sex .chosen-container,
.mm-search-filter-m-place .chosen-container,
.mm-search-filter-m-city .chosen-container,
.mm-search-filter-m-date .chosen-container,
.mm-search-filter-m-more .chosen-container,
.mm-search-filter-pl-sex .chosen-container,
.mm-search-filter-pl-age .chosen-container,
.mm-search-filter-pl-city .chosen-container,
.mm-search-filter-pl-goal .chosen-container,
.mm-search-filter-pl-more .chosen-container,
.mm-search-filter-vc-sex .chosen-container,
.mm-search-filter-vc-age .chosen-container,
.mm-search-filter-vc-city .chosen-container,
.mm-search-filter-vc-goal .chosen-container,
.mm-search-filter-vc-more .chosen-container,
.mm-search-filter-p-type .chosen-container,
.mm-search-filter-p-city .chosen-container,
.mm-search-filter-p-place .chosen-container,
.mm-search-filter-p-price-from .chosen-container,
.mm-search-filter-p-price-to .chosen-container,
.mm-search-filter-p-more .chosen-container,
.mm-search-filter-pub-type .chosen-container,
.mm-search-filter-pub-city .chosen-container,
.mm-search-filter-pub-place .chosen-container,
.mm-search-filter-pub-price-from .chosen-container,
.mm-search-filter-pub-price-to .chosen-container,
.mm-search-filter-pub-more .chosen-container {
  background-color: #fff;
  border: 1px solid #959595;
  border-right: none;
  position: relative;
  height: 44px;
  font-size: 0.875rem;
  color: #666;
  width: 100% !important;
}
.mm-search-filter-m-sex .chosen-container:hover .chosen-single,
.mm-search-filter-m-place .chosen-container:hover .chosen-single,
.mm-search-filter-m-city .chosen-container:hover .chosen-single,
.mm-search-filter-m-date .chosen-container:hover .chosen-single,
.mm-search-filter-m-more .chosen-container:hover .chosen-single,
.mm-search-filter-pl-sex .chosen-container:hover .chosen-single,
.mm-search-filter-pl-age .chosen-container:hover .chosen-single,
.mm-search-filter-pl-city .chosen-container:hover .chosen-single,
.mm-search-filter-pl-goal .chosen-container:hover .chosen-single,
.mm-search-filter-pl-more .chosen-container:hover .chosen-single,
.mm-search-filter-vc-sex .chosen-container:hover .chosen-single,
.mm-search-filter-vc-age .chosen-container:hover .chosen-single,
.mm-search-filter-vc-city .chosen-container:hover .chosen-single,
.mm-search-filter-vc-goal .chosen-container:hover .chosen-single,
.mm-search-filter-vc-more .chosen-container:hover .chosen-single,
.mm-search-filter-p-type .chosen-container:hover .chosen-single,
.mm-search-filter-p-city .chosen-container:hover .chosen-single,
.mm-search-filter-p-place .chosen-container:hover .chosen-single,
.mm-search-filter-p-price-from .chosen-container:hover .chosen-single,
.mm-search-filter-p-price-to .chosen-container:hover .chosen-single,
.mm-search-filter-p-more .chosen-container:hover .chosen-single,
.mm-search-filter-pub-type .chosen-container:hover .chosen-single,
.mm-search-filter-pub-city .chosen-container:hover .chosen-single,
.mm-search-filter-pub-place .chosen-container:hover .chosen-single,
.mm-search-filter-pub-price-from .chosen-container:hover .chosen-single,
.mm-search-filter-pub-price-to .chosen-container:hover .chosen-single,
.mm-search-filter-pub-more .chosen-container:hover .chosen-single {
  background-color: #f6f6f6;
}
.mm-search-filter-m-sex .chosen-drop,
.mm-search-filter-m-place .chosen-drop,
.mm-search-filter-m-city .chosen-drop,
.mm-search-filter-m-date .chosen-drop,
.mm-search-filter-m-more .chosen-drop,
.mm-search-filter-pl-sex .chosen-drop,
.mm-search-filter-pl-age .chosen-drop,
.mm-search-filter-pl-city .chosen-drop,
.mm-search-filter-pl-goal .chosen-drop,
.mm-search-filter-pl-more .chosen-drop,
.mm-search-filter-vc-sex .chosen-drop,
.mm-search-filter-vc-age .chosen-drop,
.mm-search-filter-vc-city .chosen-drop,
.mm-search-filter-vc-goal .chosen-drop,
.mm-search-filter-vc-more .chosen-drop,
.mm-search-filter-p-type .chosen-drop,
.mm-search-filter-p-city .chosen-drop,
.mm-search-filter-p-place .chosen-drop,
.mm-search-filter-p-price-from .chosen-drop,
.mm-search-filter-p-price-to .chosen-drop,
.mm-search-filter-p-more .chosen-drop,
.mm-search-filter-pub-type .chosen-drop,
.mm-search-filter-pub-city .chosen-drop,
.mm-search-filter-pub-place .chosen-drop,
.mm-search-filter-pub-price-from .chosen-drop,
.mm-search-filter-pub-price-to .chosen-drop,
.mm-search-filter-pub-more .chosen-drop {
  background-color: #fff;
  border: 1px solid #959595;
  border-radius: 3px;
}
.mm-search-filter-m-sex .chosen-single,
.mm-search-filter-m-place .chosen-single,
.mm-search-filter-m-city .chosen-single,
.mm-search-filter-m-date .chosen-single,
.mm-search-filter-m-more .chosen-single,
.mm-search-filter-pl-sex .chosen-single,
.mm-search-filter-pl-age .chosen-single,
.mm-search-filter-pl-city .chosen-single,
.mm-search-filter-pl-goal .chosen-single,
.mm-search-filter-pl-more .chosen-single,
.mm-search-filter-vc-sex .chosen-single,
.mm-search-filter-vc-age .chosen-single,
.mm-search-filter-vc-city .chosen-single,
.mm-search-filter-vc-goal .chosen-single,
.mm-search-filter-vc-more .chosen-single,
.mm-search-filter-p-type .chosen-single,
.mm-search-filter-p-city .chosen-single,
.mm-search-filter-p-place .chosen-single,
.mm-search-filter-p-price-from .chosen-single,
.mm-search-filter-p-price-to .chosen-single,
.mm-search-filter-p-more .chosen-single,
.mm-search-filter-pub-type .chosen-single,
.mm-search-filter-pub-city .chosen-single,
.mm-search-filter-pub-place .chosen-single,
.mm-search-filter-pub-price-from .chosen-single,
.mm-search-filter-pub-price-to .chosen-single,
.mm-search-filter-pub-more .chosen-single {
  display: block;
  height: 100%;
  line-height: 42px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mm-search-filter-m-sex div b,
.mm-search-filter-m-place div b,
.mm-search-filter-m-city div b,
.mm-search-filter-m-date div b,
.mm-search-filter-m-more div b,
.mm-search-filter-pl-sex div b,
.mm-search-filter-pl-age div b,
.mm-search-filter-pl-city div b,
.mm-search-filter-pl-goal div b,
.mm-search-filter-pl-more div b,
.mm-search-filter-vc-sex div b,
.mm-search-filter-vc-age div b,
.mm-search-filter-vc-city div b,
.mm-search-filter-vc-goal div b,
.mm-search-filter-vc-more div b,
.mm-search-filter-p-type div b,
.mm-search-filter-p-city div b,
.mm-search-filter-p-place div b,
.mm-search-filter-p-price-from div b,
.mm-search-filter-p-price-to div b,
.mm-search-filter-p-more div b,
.mm-search-filter-pub-type div b,
.mm-search-filter-pub-city div b,
.mm-search-filter-pub-place div b,
.mm-search-filter-pub-price-from div b,
.mm-search-filter-pub-price-to div b,
.mm-search-filter-pub-more div b {
  width: 20px;
  background-image: url("/arrow-down-gray.svg#base64");
  background-repeat: no-repeat;
  background-position: center left;
}
.mm-search-filter-m-sex .chosen-with-drop div b,
.mm-search-filter-m-place .chosen-with-drop div b,
.mm-search-filter-m-city .chosen-with-drop div b,
.mm-search-filter-m-date .chosen-with-drop div b,
.mm-search-filter-m-more .chosen-with-drop div b,
.mm-search-filter-pl-sex .chosen-with-drop div b,
.mm-search-filter-pl-age .chosen-with-drop div b,
.mm-search-filter-pl-city .chosen-with-drop div b,
.mm-search-filter-pl-goal .chosen-with-drop div b,
.mm-search-filter-pl-more .chosen-with-drop div b,
.mm-search-filter-vc-sex .chosen-with-drop div b,
.mm-search-filter-vc-age .chosen-with-drop div b,
.mm-search-filter-vc-city .chosen-with-drop div b,
.mm-search-filter-vc-goal .chosen-with-drop div b,
.mm-search-filter-vc-more .chosen-with-drop div b,
.mm-search-filter-p-type .chosen-with-drop div b,
.mm-search-filter-p-city .chosen-with-drop div b,
.mm-search-filter-p-place .chosen-with-drop div b,
.mm-search-filter-p-price-from .chosen-with-drop div b,
.mm-search-filter-p-price-to .chosen-with-drop div b,
.mm-search-filter-p-more .chosen-with-drop div b,
.mm-search-filter-pub-type .chosen-with-drop div b,
.mm-search-filter-pub-city .chosen-with-drop div b,
.mm-search-filter-pub-place .chosen-with-drop div b,
.mm-search-filter-pub-price-from .chosen-with-drop div b,
.mm-search-filter-pub-price-to .chosen-with-drop div b,
.mm-search-filter-pub-more .chosen-with-drop div b {
  background-image: url("/arrow-left-gray.svg#base64");
}
.mm-search-filter-m-sex .chosen-search,
.mm-search-filter-m-place .chosen-search,
.mm-search-filter-m-city .chosen-search,
.mm-search-filter-m-date .chosen-search,
.mm-search-filter-m-more .chosen-search,
.mm-search-filter-pl-sex .chosen-search,
.mm-search-filter-pl-age .chosen-search,
.mm-search-filter-pl-city .chosen-search,
.mm-search-filter-pl-goal .chosen-search,
.mm-search-filter-pl-more .chosen-search,
.mm-search-filter-vc-sex .chosen-search,
.mm-search-filter-vc-age .chosen-search,
.mm-search-filter-vc-city .chosen-search,
.mm-search-filter-vc-goal .chosen-search,
.mm-search-filter-vc-more .chosen-search,
.mm-search-filter-p-type .chosen-search,
.mm-search-filter-p-city .chosen-search,
.mm-search-filter-p-place .chosen-search,
.mm-search-filter-p-price-from .chosen-search,
.mm-search-filter-p-price-to .chosen-search,
.mm-search-filter-p-more .chosen-search,
.mm-search-filter-pub-type .chosen-search,
.mm-search-filter-pub-city .chosen-search,
.mm-search-filter-pub-place .chosen-search,
.mm-search-filter-pub-price-from .chosen-search,
.mm-search-filter-pub-price-to .chosen-search,
.mm-search-filter-pub-more .chosen-search {
  border-bottom: 1px solid #959595;
}
.mm-search-filter-m-sex .chosen-results li,
.mm-search-filter-m-place .chosen-results li,
.mm-search-filter-m-city .chosen-results li,
.mm-search-filter-m-date .chosen-results li,
.mm-search-filter-m-more .chosen-results li,
.mm-search-filter-pl-sex .chosen-results li,
.mm-search-filter-pl-age .chosen-results li,
.mm-search-filter-pl-city .chosen-results li,
.mm-search-filter-pl-goal .chosen-results li,
.mm-search-filter-pl-more .chosen-results li,
.mm-search-filter-vc-sex .chosen-results li,
.mm-search-filter-vc-age .chosen-results li,
.mm-search-filter-vc-city .chosen-results li,
.mm-search-filter-vc-goal .chosen-results li,
.mm-search-filter-vc-more .chosen-results li,
.mm-search-filter-p-type .chosen-results li,
.mm-search-filter-p-city .chosen-results li,
.mm-search-filter-p-place .chosen-results li,
.mm-search-filter-p-price-from .chosen-results li,
.mm-search-filter-p-price-to .chosen-results li,
.mm-search-filter-p-more .chosen-results li,
.mm-search-filter-pub-type .chosen-results li,
.mm-search-filter-pub-city .chosen-results li,
.mm-search-filter-pub-place .chosen-results li,
.mm-search-filter-pub-price-from .chosen-results li,
.mm-search-filter-pub-price-to .chosen-results li,
.mm-search-filter-pub-more .chosen-results li {
  border-bottom: inherit;
  padding: 5px 20px;
}
.mm-search-filter-m-sex .chosen-results li:last-child,
.mm-search-filter-m-place .chosen-results li:last-child,
.mm-search-filter-m-city .chosen-results li:last-child,
.mm-search-filter-m-date .chosen-results li:last-child,
.mm-search-filter-m-more .chosen-results li:last-child,
.mm-search-filter-pl-sex .chosen-results li:last-child,
.mm-search-filter-pl-age .chosen-results li:last-child,
.mm-search-filter-pl-city .chosen-results li:last-child,
.mm-search-filter-pl-goal .chosen-results li:last-child,
.mm-search-filter-pl-more .chosen-results li:last-child,
.mm-search-filter-vc-sex .chosen-results li:last-child,
.mm-search-filter-vc-age .chosen-results li:last-child,
.mm-search-filter-vc-city .chosen-results li:last-child,
.mm-search-filter-vc-goal .chosen-results li:last-child,
.mm-search-filter-vc-more .chosen-results li:last-child,
.mm-search-filter-p-type .chosen-results li:last-child,
.mm-search-filter-p-city .chosen-results li:last-child,
.mm-search-filter-p-place .chosen-results li:last-child,
.mm-search-filter-p-price-from .chosen-results li:last-child,
.mm-search-filter-p-price-to .chosen-results li:last-child,
.mm-search-filter-p-more .chosen-results li:last-child,
.mm-search-filter-pub-type .chosen-results li:last-child,
.mm-search-filter-pub-city .chosen-results li:last-child,
.mm-search-filter-pub-place .chosen-results li:last-child,
.mm-search-filter-pub-price-from .chosen-results li:last-child,
.mm-search-filter-pub-price-to .chosen-results li:last-child,
.mm-search-filter-pub-more .chosen-results li:last-child {
  border-bottom: 0;
  border-radius: 0 0 3px 3px;
}
.mm-search-filter-m-sex .chosen-results li.highlighted,
.mm-search-filter-m-place .chosen-results li.highlighted,
.mm-search-filter-m-city .chosen-results li.highlighted,
.mm-search-filter-m-date .chosen-results li.highlighted,
.mm-search-filter-m-more .chosen-results li.highlighted,
.mm-search-filter-pl-sex .chosen-results li.highlighted,
.mm-search-filter-pl-age .chosen-results li.highlighted,
.mm-search-filter-pl-city .chosen-results li.highlighted,
.mm-search-filter-pl-goal .chosen-results li.highlighted,
.mm-search-filter-pl-more .chosen-results li.highlighted,
.mm-search-filter-vc-sex .chosen-results li.highlighted,
.mm-search-filter-vc-age .chosen-results li.highlighted,
.mm-search-filter-vc-city .chosen-results li.highlighted,
.mm-search-filter-vc-goal .chosen-results li.highlighted,
.mm-search-filter-vc-more .chosen-results li.highlighted,
.mm-search-filter-p-type .chosen-results li.highlighted,
.mm-search-filter-p-city .chosen-results li.highlighted,
.mm-search-filter-p-place .chosen-results li.highlighted,
.mm-search-filter-p-price-from .chosen-results li.highlighted,
.mm-search-filter-p-price-to .chosen-results li.highlighted,
.mm-search-filter-p-more .chosen-results li.highlighted,
.mm-search-filter-pub-type .chosen-results li.highlighted,
.mm-search-filter-pub-city .chosen-results li.highlighted,
.mm-search-filter-pub-place .chosen-results li.highlighted,
.mm-search-filter-pub-price-from .chosen-results li.highlighted,
.mm-search-filter-pub-price-to .chosen-results li.highlighted,
.mm-search-filter-pub-more .chosen-results li.highlighted {
  background-color: #f6f6f6;
}
.mm-search-filter-m-sex .chosen-results .disabled-result,
.mm-search-filter-m-place .chosen-results .disabled-result,
.mm-search-filter-m-city .chosen-results .disabled-result,
.mm-search-filter-m-date .chosen-results .disabled-result,
.mm-search-filter-m-more .chosen-results .disabled-result,
.mm-search-filter-pl-sex .chosen-results .disabled-result,
.mm-search-filter-pl-age .chosen-results .disabled-result,
.mm-search-filter-pl-city .chosen-results .disabled-result,
.mm-search-filter-pl-goal .chosen-results .disabled-result,
.mm-search-filter-pl-more .chosen-results .disabled-result,
.mm-search-filter-vc-sex .chosen-results .disabled-result,
.mm-search-filter-vc-age .chosen-results .disabled-result,
.mm-search-filter-vc-city .chosen-results .disabled-result,
.mm-search-filter-vc-goal .chosen-results .disabled-result,
.mm-search-filter-vc-more .chosen-results .disabled-result,
.mm-search-filter-p-type .chosen-results .disabled-result,
.mm-search-filter-p-city .chosen-results .disabled-result,
.mm-search-filter-p-place .chosen-results .disabled-result,
.mm-search-filter-p-price-from .chosen-results .disabled-result,
.mm-search-filter-p-price-to .chosen-results .disabled-result,
.mm-search-filter-p-more .chosen-results .disabled-result,
.mm-search-filter-pub-type .chosen-results .disabled-result,
.mm-search-filter-pub-city .chosen-results .disabled-result,
.mm-search-filter-pub-place .chosen-results .disabled-result,
.mm-search-filter-pub-price-from .chosen-results .disabled-result,
.mm-search-filter-pub-price-to .chosen-results .disabled-result,
.mm-search-filter-pub-more .chosen-results .disabled-result {
  color: #999;
}
.mm-search-filter-m-sex .chosen-container,
.mm-search-filter-pl-sex .chosen-container,
.mm-search-filter-vc-sex .chosen-container,
.mm-search-filter-p-type .chosen-container,
.mm-search-filter-pub-type .chosen-container,
.mm-search-filter-m-sex .chosen-single,
.mm-search-filter-pl-sex .chosen-single,
.mm-search-filter-vc-sex .chosen-single,
.mm-search-filter-p-type .chosen-single,
.mm-search-filter-pub-type .chosen-single,
.mm-search-filter-m-sex__select,
.mm-search-filter-pl-sex__select,
.mm-search-filter-vc-sex__select,
.mm-search-filter-p-type__select,
.mm-search-filter-pub-type__select {
  border-radius: 3px 0 0 3px;
}
.mm-search-filter-p-place__input,
.mm-search-filter-pub-place__input {
  display: block;
  width: 100%;
  background: #fff;
  border: 1px solid #959595;
  border-right: none;
  height: 44px;
  line-height: 42px;
  font-size: 0.875rem;
  color: #666;
  padding: 0 20px;
}
.mm-search-filter-p-place__input:hover,
.mm-search-filter-pub-place__input:hover {
  background: #f6f6f6;
}
.mm-search-filter-p-place__input::placeholder,
.mm-search-filter-pub-place__input::placeholder {
  color: inherit;
}
.mm-search-filter-p-type {
  width: 135px;
}
.mm-search-filter-p-city {
  width: 210px;
}
.mm-search-filter-p-place {
  width: 255px;
}
.mm-search-filter-p-price-from {
  width: 125px;
}
.mm-search-filter-p-price-to {
  width: 130px;
}
.mm-search-filter-pub-type {
  width: 135px;
}
.mm-search-filter-pub-city {
  width: 210px;
}
.mm-search-filter-pub-place {
  width: 255px;
}
.mm-search-filter-pub-price-from {
  width: 125px;
}
.mm-search-filter-pub-price-to {
  width: 130px;
}
.mm-search-filter-pl-sex {
  width: 140px;
}
.mm-search-filter-pl-age {
  width: 212px;
}
.mm-search-filter-pl-city {
  width: 255px;
}
.mm-search-filter-pl-goal {
  width: 255px;
}
.mm-search-filter-vc-sex {
  width: 140px;
}
.mm-search-filter-vc-age {
  width: 212px;
}
.mm-search-filter-vc-city {
  width: 255px;
}
.mm-search-filter-vc-goal {
  width: 255px;
}
.mm-search-filter-m-sex {
  width: 135px;
}
.mm-search-filter-m-place {
  width: 210px;
}
.mm-search-filter-m-city {
  width: 255px;
}
.mm-search-filter-m-date {
  width: 255px;
}
.mm-search-filter-m-more,
.mm-search-filter-p-more,
.mm-search-filter-pub-more,
.mm-search-filter-pl-more,
.mm-search-filter-vc-more {
  border: 1px solid #959595;
  border-radius: 0 3px 3px 0;
  background-color: #fff;
}
.mm-search-filter-m-more:hover .mm-search-filter-m-more-label,
.mm-search-filter-p-more:hover .mm-search-filter-p-more-label,
.mm-search-filter-pub-more:hover .mm-search-filter-pub-more-label,
.mm-search-filter-pl-more:hover .mm-search-filter-pl-more-label,
.mm-search-filter-vc-more:hover .mm-search-filter-vc-more-label {
  background-color: #f6f6f6;
}
.mm-search-filter-m-more-label,
.mm-search-filter-p-more-label,
.mm-search-filter-pub-more-label,
.mm-search-filter-pl-more-label,
.mm-search-filter-vc-more-label {
  display: block;
  font-size: 0;
  border-radius: inherit;
  height: 42px;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
}
.mm-search-filter-m-more-label__txt,
.mm-search-filter-p-more-label__txt,
.mm-search-filter-pub-more-label__txt,
.mm-search-filter-pl-more-label__txt,
.mm-search-filter-vc-more-label__txt {
  color: #666;
  font-size: 0.875rem;
  vertical-align: middle;
}
.mm-search-filter-m-more-label__icn,
.mm-search-filter-p-more-label__icn,
.mm-search-filter-pub-more-label__icn,
.mm-search-filter-pl-more-label__icn,
.mm-search-filter-vc-more-label__icn {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 40px;
  fill: #666;
}
.mm-search-filter-m-more-drop,
.mm-search-filter-p-more-drop,
.mm-search-filter-pub-more-drop,
.mm-search-filter-pl-more-drop,
.mm-search-filter-vc-more-drop {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #959595;
  border-top: 0;
  padding: 10px 20px;
  z-index: 1;
  border-radius: 3px;
}
.mm-search-fields {
  font-size: 0;
}
.mm-search-query {
  display: inline-block;
  vertical-align: top;
  font-size: 1rem;
}
.mm-search-query {
  width: 100%;
  height: 45px;
  position: relative;
}
.mm-search-query__input {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #959595;
  padding: 0 50px;
  line-height: 22px;
}
.mm-search-query__input::placeholder {
  font-style: italic;
  font-size: 0.75rem;
  color: #999;
}
.mm-search-query__submit {
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background: url("/search-glass.svg#base64") center no-repeat transparent;
  border: none;
  box-shadow: none;
  top: 50%;
  margin-top: -12px;
  right: 13px;
  opacity: 0.7;
}
.mm-search-query__submit:hover {
  opacity: 1;
}
.mm-search-query-mic {
  width: 17px;
  display: block;
  height: 29px;
  position: absolute;
  left: 13px;
  top: 50%;
  color: #b2b3b3;
  text-decoration: none;
  margin-top: -14.5px;
}
.mm-search-query-mic:hover {
  color: #808080;
}
.mm-search-query-mic__icn {
  fill: currentColor;
  display: block;
  width: 100%;
  height: 100%;
}
.mm-search-params {
  margin-top: 10px;
}
.mm-search-params:after {
  display: block;
  clear: both;
  content: '';
}
.mm-search-help {
  font-size: 0.75rem;
  float: left;
  padding-left: 20px;
  position: relative;
}
.mm-search-help__txt {
  color: #fff;
  text-shadow: 0px 1px 1px rgba(0,0,0,0.7);
  display: inline;
  vertical-align: middle;
}
.mm-search-help .icn-help-cont {
  top: 15px;
  left: 15px;
}
.mm-search-help-show {
  display: inline-block;
  vertical-align: middle;
  text-shadow: 0px 1px 1px rgba(0,0,0,0.7);
  color: #b1e47f;
  text-decoration: none;
  margin-left: 5px;
}
.mm-search-help-show__icn {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 1px;
  width: 10px;
  height: 10px;
  fill: currentColor;
}
.mm-search-help-show__txt {
  display: inline;
  vertical-align: middle;
  text-decoration: underline;
}
.mm-search-help-show:hover .mm-search-help-show__txt {
  text-decoration: none;
}
.mm-search-help-tip {
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  padding: 3px;
  border-radius: 50%;
}
.mm-search-attr {
  float: right;
}
.mm-search-attr-itm {
  text-align: right;
  display: inline-block;
  margin-left: 15px;
}
.mm-search-attr-itm__checkbox-1 {
  display: none;
}
.mm-search-attr-itm__label-1 {
  color: #fff;
  font-size: 0.75rem;
  padding-left: 25px;
  cursor: pointer;
  position: relative;
}
.mm-search-attr-itm__label-1:before {
  content: "";
  top: -3px;
  left: 0;
  position: absolute;
  background-image: url("/control-checkbox-off.svg#base64");
  width: 18px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.mm-search-attr-itm__checkbox-1:checked + .mm-search-attr-itm__label-1::before {
  background-image: url("/control-checkbox-on.svg#base64");
}
.mm-search-attr-itm {
  text-align: right;
  display: inline-block;
  margin-left: 15px;
}
.mm-search-attr-itm__checkbox-2 {
  display: none;
}
.mm-search-attr-itm__label-2 {
  color: #fff;
  font-size: 0.75rem;
  padding-left: 25px;
  cursor: pointer;
  position: relative;
}
.mm-search-attr-itm__label-2:before {
  content: "";
  top: -3px;
  left: 0;
  position: absolute;
  background-image: url("/control-checkbox-off.svg#base64");
  width: 18px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.mm-search-attr-itm__checkbox-2:checked + .mm-search-attr-itm__label-2::before {
  background-image: url("/control-checkbox-on.svg#base64");
}
.mm-search-attr-itm {
  text-align: right;
  display: inline-block;
  margin-left: 15px;
}
.mm-search-attr-itm__checkbox-3 {
  display: none;
}
.mm-search-attr-itm__label-3 {
  color: #fff;
  font-size: 0.75rem;
  padding-left: 25px;
  cursor: pointer;
  position: relative;
}
.mm-search-attr-itm__label-3:before {
  content: "";
  top: -3px;
  left: 0;
  position: absolute;
  background-image: url("/control-checkbox-off.svg#base64");
  width: 18px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.mm-search-attr-itm__checkbox-3:checked + .mm-search-attr-itm__label-3::before {
  background-image: url("/control-checkbox-on.svg#base64");
}
.nav-side {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  background-color: #f4f4f4;
}
.nav-side-top-my-info {
  color: #fff;
  background-color: #9ccd6a;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid #3b871b;
  border-radius: 5px;
  width: 90%;
  margin: 15px auto 20px auto;
  padding: 10px 20px;
  position: relative;
}
.nav-side-top-my-info::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  bottom: -8px;
  left: 50%;
  transform: rotate(45deg);
  border: inherit;
  border-top-color: transparent;
  border-left-color: transparent;
  margin-left: -7.5px;
  background-color: inherit;
}
.nav-side-top-my-info__help {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  padding: 3px;
}
.nav-side-top-my-info__help .icn-help-cont {
  right: 15px;
  top: 15px;
}
.nav-side-top-tape {
  margin: 15px auto 0;
  width: 90%;
}
.nav-side-top-tape-btn {
  display: block;
  position: relative;
  border-radius: 5px;
  background-color: #cfcfcf;
  border: 1px solid #a9a9a9;
  text-decoration: none;
  color: #fff;
  padding: 6px 0;
  font-size: 1.125rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.nav-side-top-my-info__txt,
.nav-side-top-tape-btn__txt {
  text-shadow: 1px 1px 1px rgba(50,50,50,0.5);
}
.nav-side-top-tape-btn__help {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  padding: 3px;
}
.nav-side-top-tape-btn__help .icn-help-cont {
  right: 15px;
  top: 15px;
}
.nav-side-top-tape-desc {
  color: #666;
  font-size: 0.75rem;
  padding: 10px 20px 0;
}
.my-side-menu {
  list-style: none;
  font-size: 0.8125rem;
}
.my-side-menu__item {
  border-bottom: 1px solid #cfcfcf;
  height: 30px;
  position: relative;
  cursor: pointer;
  color: #666;
}
.my-side-menu__item:hover,
.my-side-menu__item_active {
  background-color: #69bdb7;
  background: linear-gradient(to bottom, #69bdb7 0%, #65b5ad 50%, #5eaba4 100%);
  color: #fff;
}
.my-side-menu__edit {
  fill: #447b76;
  position: absolute;
  top: 7px;
  right: 25px;
  cursor: pointer;
  height: 17px;
  width: 17px;
}
.my-side-menu__link {
  display: block;
  color: inherit;
  height: 100%;
  text-decoration: none;
}
.my-side-menu__label {
  padding: 0 40px 0 30px;
  line-height: 29px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.my-side-menu__icon {
  height: 19px;
  width: 19px;
  position: absolute;
  top: 50%;
  margin-top: -9.5px;
  fill: currentColor;
  left: 25px;
}
.my-side-menu__count {
  background-color: #4594a2;
  color: #fff;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 0 3px;
  border-radius: 3px;
  text-align: center;
  font-size: 0.5625rem;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 20px;
}
.setting-menu {
  color: #666;
  font-size: 0.8125rem;
  list-style: none;
  margin-top: 10px;
}
.setting-menu__item {
  border-top: 1px solid #cfcfcf;
  height: 40px;
  position: relative;
}
.setting-menu__item:hover,
.setting-menu__item_active {
  color: #69bdb7;
}
.setting-menu__link {
  text-decoration: none;
  display: block;
  color: inherit;
  height: 100%;
}
.setting-menu__label {
  padding: 0 5px 0 50px;
  line-height: 39px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.setting-menu__icon {
  height: 19px;
  width: 19px;
  position: absolute;
  top: 50%;
  margin-top: -9.5px;
  fill: #a7a2a2;
  left: 20px;
}
.footer {
  height: 40px;
  background-color: #089090;
}
.footer-content {
  height: 100%;
  color: #fff;
  position: relative;
}
.footer_is-dialog {
  display: none;
}
.footer-copy-wrap {
  height: 100%;
  display: table;
  position: absolute;
  left: 0;
  top: 0;
}
.footer-copy {
  font-size: 0;
  display: table-cell;
  vertical-align: middle;
  list-style: none;
}
.footer-copy__year,
.footer-copy__title,
.footer-copy__right {
  display: inline-block;
  font-size: 0.75rem;
  border-right: 1px solid currentColor;
  padding: 0 6px;
}
.footer-copy__year {
  padding-left: 11px;
}
.footer-copy__right {
  border-right: none;
}
.footer-menu-wrap {
  display: table;
  width: 100%;
  height: 100%;
}
.footer-menu {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  margin: 0 auto;
  list-style: none;
  font-size: 0;
}
.footer-menu__item {
  display: inline-block;
  font-size: 0.75rem;
  border-right: 1px solid currentColor;
  padding: 0 6px;
}
.footer-menu__item:last-child {
  border-right: none;
}
.footer-menu__link {
  color: inherit;
  text-decoration: none;
  display: block;
}
.footer-menu__link:hover {
  text-decoration: underline;
}
.footer-social-wrap {
  display: table;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.footer-social {
  display: table-cell;
  vertical-align: middle;
}
.footer-social-txt {
  display: inline-block;
  font-size: 0.75rem;
  margin-right: 15px;
}
.footer-social-list {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  list-style: none;
}
.footer-social-list__item {
  font-size: 0.75rem;
  display: inline-block;
  margin: 0 4px;
  opacity: 0.7;
}
.footer-social-list__item:hover {
  opacity: 1;
}
.footer-social-list__item:last-child {
  margin-right: 11px;
}
.footer-social-list__link {
  display: block;
  width: 30px;
  height: 30px;
}
.footer-social-list__icn {
  width: 100%;
  height: 100%;
  fill: #fff;
  display: block;
  padding: 2px;
}
.footer-social-list__item_fb .footer-social-list__icn {
  padding: 0;
}
