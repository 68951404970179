.bc-search-cand
  border-radius: 10px
  overflow: hidden
  border: 1px solid #b4b4b4
  background-color: #fafafa
  width: 100%
  font-size: 1rem
  position: relative

.bc-search-cand-acts
  width: 22px
  height: 22px
  position: absolute
  top: 10px
  right: 10px
  z-index: $zi-bc-search-cand-acts

.bc-search-cand-head
  height: 270px
  position: relative
  width: 100%
  overflow: hidden

.bc-search-cand-over
  display: block
  background: url("../img/bg/profile-bg-locked.png") repeat center
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  z-index: $zi-bc-search-cand-overlay

.bc-search-cand-lock
  position: absolute
  top: 50%
  left: 50%
  width: 45px
  height: 63px
  margin-top: -(@height / 2)
  margin-left: -(@width / 2)

.bc-search-cand-lock__icn
  width: 100%
  height: 100%
  fill: rgba(255,255,255,.7)
  stroke: rgba(0,0,0,.1)
  stroke-width: 3

.bc-search-cand-lock-hint
  width: 15px
  height: 15px
  padding: 3px
  position: absolute
  bottom: -5px
  right: -5px

  .icn-help-cont
    bottom: 12px
    right: 12px

.bc-search-cand-status
  position: absolute
  color: #fff
  font-size: (10/16)rem
  width: 60px
  height: 14px
  font-weight: 300
  line-height: @height
  bottom: 10px
  left: 10px
  text-align: center
  border-radius: 3px
  z-index: $zi-bc-search-cand-status

  &_on
    background-color: #95cd7d

  &_off
    background-color: #bababa

.bc-search-cand-ava
  width: 100%
  height: 100%

.bc-search-cand-ava__img
  display: block
  width: 100%
  height: 100%

  .bc-search-cand_locked &
    filter: blur(10px)

.bc-search-cand-body
  padding: 10px
  position: relative

.bc-search-cand-bday
  position: absolute
  width: 40px
  height: 40px
  top: 0
  right: 40%
  margin-top: -(@height / 2)

.bc-search-cand-top
  display: table
  width: 100%
  height: 44px

.bc-search-cand-name
.bc-search-cand-like
  display: table-cell
  vertical-align: middle

.bc-search-cand-name
  padding-right: 10px

.bc-search-cand-name__txt
  font-weight: bold
  color: #343434
  font-size: 1rem
  overflow: hidden
  text-decoration: none

  &:hover
    text-decoration: underline

.bc-search-cand-like
  width: 90px

  &__btn
    display: block
    width: 100px
    height: 30px
    line-height: (@height - 2px)
    border-radius: 3px
    font-size: (14/16)rem
    font-weight: normal

.bc-search-cand-match
  position: absolute
  right: 5px
  bottom: 5px
  z-index: 1

  &__cnt
    font-size: (36/16)rem
    line-height: 1
    color: #fff
    font-weight: 300
