.am-search-list
  list-style: none
  padding-bottom: 20px



.am-search-hint
  background-color: #f9fcfc
  padding: 15px
  position: relative
  border: 1px dashed #419e98
  margin-bottom: 30px

  &-query
    position: absolute
    width: 155px
    height: 75px
    top: -53px
    right: 0
    background: url("../img/main/search-query.png") no-repeat center transparent

    &__goto
      color: #4b4b4b
      font-size: (12/16)rem
      position: absolute
      left: 25px
      bottom: 13px
      line-height: 1
      text-decoration: none
      display: block

      &:hover
        text-decoration: underline

  &__title
    text-transform: uppercase
    color: #4594a2
    font-weight: bold
    line-height: 1
    font-size: (14/16)rem
    border-radius: 5px
    margin-bottom: 10px

  &__txt
    font-weight: 300
    color: #5f5f5f
    line-height: 1.2
    padding-left: 25px
    font-style: italic





.am-search-accords
  font-size: 0
  border: 1px solid #66d4d1
  padding-left: 20px
  color: #fff
  border-radius: 5px
  margin-bottom: 30px

  &_80
    background-color: #68bab4
    background: linear-gradient(to bottom, #5dc2bb 0%, #44a69f 49%, #3da29b 50%, #4ba9a2 100%)

  &_70
    background-color: #5dc2bb
    background: linear-gradient(to bottom, #5dc2bb 0%, #4fbdb5 49%, #44b8b0 50%, #51bdb6 100%)

  &_30
    border-color: #e8e8e8
    background-color: #d0d0d0
    background: linear-gradient(to bottom, #d0d0d0 0%, #cdcdcd 49%, #c8c8c8 50%, #ccc 100%)

  &_30 &-match
    border-right-color: #c0c0c0

  &_30 &-menu
    border-left-color: #dadada

  &-match
    display: inline-block
    vertical-align: middle
    padding-right: 20px
    border-right: 1px solid #31938b
    text-align: center
    font-weight: 300

    &__txt
      display: inline-block
      vertical-align: middle
      font-size: (12/16)rem
      text-transform: uppercase
      text-align: right
      line-height: 1
      width: 100px

    &__cnt
      font-size: (30/16)rem
      display: inline-block
      vertical-align: middle
      margin-left: 13px

  &-menu
    display: inline-block
    vertical-align: middle
    border-left: 1px solid #63c6c1
    padding: 0 10px

  &-yes
  &-no
  &-unknown
    display: inline-block
    vertical-align: middle

    &-label
      display: block
      height: 42px
      line-height: @height
      text-align: center
      padding: 0 20px
      text-align: center

      &__txt
        font-size: (18/16)rem
        vertical-align: middle

      &__icn
        font-size: (10/16)rem
        display: inline-block
        margin-left: 10px
        vertical-align: middle




.am-search-quests
  list-style: none
  margin: 0 -14px
  font-size: 0

.am-search-quests-cell
  padding: 0 14px
  display: inline-block
  vertical-align: top
  width: 25%
  margin-bottom: 28px









.am-search-more
  padding-bottom: 30px

.am-search-more__btn
  border-radius: 3px
  width: 120px
  display: block
  height: 32px
  line-height: (@height - 2px)
  margin: 0 auto 20px

.am-search-more__loader
  display: block
  height: 30px
  width: 30px
  margin: 0 auto
