.panel-search-top
  display: table
  width: 100%
  height: 82px

.panel-search-name
.panel-search-toggler
  display: table-cell
  vertical-align: middle

.panel-search-toggler
  width: 170px
  padding-left: 30px

  &-btn
    float: right
    display: block
    text-decoration: none
    width: 140px
    height: 23px
    line-height: (@height - 2px)
    color: #999
    border: 1px dotted currentColor
    border-radius: 3px
    text-align: center
    font-size: 0

    &:hover
      background-color: #999
      color: #fff

    &__txt
      color: currentColor
      font-size: (12/16)rem
      display: inline
      vertical-align: middle

    &__icn
      display: inline-block
      vertical-align: middle
      margin-left: 10px
      width: 10px
      height: 10px
      fill: currentColor

.panel-search-name__title
  font-size: (30/16)rem
  font-weight: 300
  color: #4b4b4b

.panel-search-filter
  padding-bottom: 35px
